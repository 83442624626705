@import "@mksap/styles/common_helpers";


#modal-root {
	& > div {
		position: relative;
		// Add a z-index to the elements that wrap modals so that nested modals' overlays display properly
		// cf: adding a z-index here so modals cover things like the app-bar search input button.
		z-index: 1000;
	}
	.jump-to-content {
		display: block;
		//justify-content: flex-start;
		align-items: center;
		padding: 0.5em 1.5em;
		cursor: pointer;
		background-color: $primary;
		a {
			text-decoration: none;
			color: $body-bg;
			display: block;
		}
		.svg-icon {
			margin-left: 10px;
		}
		&:hover {
			background-color: darken($primary, 5%);
			text-decoration: none;
		}
	}
	.modal-title {
		font-size:$font-size-lg;
	}
	.modal-content {
		padding-top: env(safe-area-inset-top);
		padding-bottom: env(safe-area-inset-bottom);
	}
	.modal-content-full-height {
		min-height: 100%;
	}
	.modal-fullscreen-lg-down {
		.modal-body {
			background-color: $body-bg;
			@include in-color-mode(dark) {
				background-color:$gray-900;
			}
		}
	}
}
