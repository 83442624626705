// For display settings > font size controls.
// 1.125-major second - https://type-scale.com/
.fs-dn-3 {
	font-size: calc($font-size-root - 0.2vw);
}
.fs-dn-2 {
	font-size: calc($font-size-root - 0.15vw);
}
.fs-dn-1 {
	font-size: calc($font-size-root - 0.07vw);
}
.fs-up-1 {
	font-size: calc($font-size-root + 0.07vw);
}
.fs-up-2 {
	font-size: calc($font-size-root + 0.15vw);
}
.fs-up-3 {
	font-size: calc($font-size-root + 0.2vw);
}

.fs-inherit {
	font-size: inherit !important;
}

.container-reading {
	width: min(70ch, 100%); // 100 width of container and max character 70
	font-size: clamp(1rem, 3vw, 1.15rem);
}

.cursor-pointer {
	cursor: pointer;
}
.small-font {
	font-size: $small-font-size;
}
.smaller-font {
	font-size: $small-font-size * .88;
}
sup {
  	vertical-align: super;
  	font-size: .5em;
	top:inherit;
}
.small-caps {
	font-variant: small-caps;
}

li.no-bullet {
	list-style: none;
}
h1, .h1 {
	font-weight: 600;
}
//h3 section titles
h3.headline {
	@include font-size($h3-font-size);
	font-weight: 600;
	margin-bottom:0;
	line-height: 1.3;
	color:var(--bs-heading-color);
}
h1.page-title {
	@include font-size($h1-font-size);
	font-weight: bold;
	color:var(--bs-heading-color);
}
.frostGlass.card {
	background-color: transparent;
    background-image: linear-gradient(180deg, #ccc 1px, #f2f4ff90 1px),
    linear-gradient(45deg, #fffa 6px, #fff0 10px),
    linear-gradient(-45deg, #fffa 6px, #fff0 10px);
    backdrop-filter: blur(5px);
    //box-shadow: inset 0 2px 2px 0 ;
	@include in-color-mode(dark) {
		.card-title {
			color:$body-color;
		}
	}
}
