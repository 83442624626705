// ****************************************
// Override Bootstrap variables
// ****************************************
//
// Color system
//
// scss-docs-start gray-color-variables
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables $acp_blue_dark=003479 $acp_blue_mid=#0063BE $acp_blue_light=#00A0DF $acp_blue_lightest=#7CD0F3
$blue:    #003479; //primary //$acp_blue_dark
$navy: #02174A; //secondary
$light-blue: #7CD0F3; //tertiary //$acp_blue_lightest
$medium-blue: #0063BE; //$acp_blue_mid //#7096D1; //tertiary //$
$green: 	#2D862D;
$light-green: #40A03B;
$red:#D04343;
$red-orange:#D65222;
$yellow:#E2B134;
$gold: #FFC226;
$pink: #d63384;
// scss-docs-end color-variables
// scss-docs-start custom color-variables
$acp_green: 				#007e66;
$acp_green_mid: 		#2eb135;
$acp_green_bright:	#91c83c;
//$acp_green_light: 	#d7e241;
$acp_blue_dark:			#003479; //blue1 rgb(0, 52, 121)   #003479 //primary
$acp_blue_mid: 			#0063BE; //blue2	rgb(0, 99, 190)	  #0063BE //$medium-blue
$acp_blue_light:		#00A0DF; //blue3	rgb(0, 160, 223)	#00A0DF
$acp_blue_lightest:	#7CD0F3; //blue4 rgb(124, 208, 243)#7CD0F3 //light-blue
$acp_red: 					#dc291e;
$acp_magenta: 			#d21146;
$acp_orange: 				#e66b00;
$acp_yellow: 				#ffe000;
$acp_gold: 					#9D8319;
$slate: 						#3f5366;
$muted:							#6c757d;
$muted-dark:				#4a5056;
$highlighter-yellow:#efdd00;
$highlighter-orange:#ffa03e;
$highlighter-green: #4ce62a;
$highlighter-pink: 	#ff2a9d;
$highlighter-purple:#bc13fe;
$highlighter-trash: #c8291e;
$highlighter-info: 	#00a0df;
// scss-docs-end custom color-variables

// scss-docs-start colors-map
$colors: (
  "blue":       			$blue,
	"navy":       			$navy,
	"light-blue":       $light-blue,
	"medium-blue":      $medium-blue,
	"green":      			$green,
	"light-green":      $light-green,
	"red":      				$red,
	"red-orange":      	$red-orange,
  "yellow":     			$yellow,
	"gold":							$gold,
  "black":      			$black,
  "white":      			$white,
  "gray":       			$gray-600,
  "gray-dark":  			$gray-800,
	"acp_blue_light":		$acp-blue-light,
	"acp_blue_lightest":$acp-blue-lightest,
	"acp_blue_dark":    $acp-blue-dark,
	"acp_blue_mid": 		$acp-blue-mid,
	"acp_gold":					$gold,
	"acp_orange":				$red-orange,
	"acp_red":					$acp-red,
	"acp_magenta": 			$acp-magenta,
	"acp_green": 				$acp-green,
	"acp_green_mid": 		$acp-green-mid,
	"acp_green_bright":	$acp-green-bright,
	"acp_yellow": 			$acp-yellow,
	"slate": 						$slate,
	"muted": 						$muted,
	"muted-dark": 			$muted-dark,
	"highlighter-yellow": $highlighter-yellow,
	"highlighter-orange": $highlighter-orange,
	"highlighter-green":$highlighter-green,
	"highlighter-pink":	$highlighter-pink,
	"highlighter-purple":$highlighter-purple,
	"highlighter-trash": $highlighter-trash,
	"highlighter-info":	$highlighter-info,
	);
// scss-docs-end colors-map

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:   4.5;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark:      $gray-900;
$color-contrast-light:     $gray-100;


// scss-docs-end theme-color-variables
$primary:  			$blue;
$secondary:			$navy;
$tertiary:			$medium-blue;
$success:  			$light-green;
$danger:   			$red-orange;
$correct:				$light-green;
$incorrect:			$red-orange;
$info:   	 			$medium-blue;
$warning:  			$gold;
$unsure:				$gold;
$light:         $gray-100;
$dark:          $gray-800;
//[data-bs-theme=dark]
$primary-dark:   tint-color($primary, 55%);
$secondary-dark: tint-color($secondary, 5%);
$tertiary-dark:  shade-color($tertiary, 40%);
$success-dark:   tint-color($success, 40%);
$danger-dark:    tint-color($danger, 20%); //text-danger-emphasis, bg-danger, btn-outline-danger
$correct-dark:	 tint-color($correct, 40%);
$incorrect-dark: shade-color($incorrect, 10%);
$info-dark:      tint-color($info, 45%);
$warning-dark:   shade-color($warning, 35%);
$unsure-dark: 	 shade-color($unsure, 15%);
$light-dark:		 shade-color($light, 20%);//RGBA(198, 199, 200, 1) !important
$dark-dark:			 shade-color($dark, 60%);
$highlighter-green-dark:  shade-color($highlighter-green, 40%);
$highlighter-pink-dark:   shade-color($highlighter-pink, 40%);
$highlighter-yellow-dark: shade-color($highlighter-yellow, 40%);

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": 			$primary,
  "secondary": 		$secondary,
	"tertiary": 		$tertiary,
  "success": 			$success,
  "danger": 			$danger,
	"correct":			$correct,
	"incorrect":		$incorrect,
	"unsure":				$unsure,
  "info": 				$info,
  "warning": 			$warning,
	"light":      	$light,
  "dark":       	$dark,
  "highlighter-green": 	$highlighter-green,
  "highlighter-pink":		$highlighter-pink,
  "highlighter-yellow":	$highlighter-yellow,
);



// scss-docs-start theme-text-variables
$primary-text-emphasis:   shade-color($primary, 20%);
$secondary-text-emphasis: shade-color($secondary, 60%);
$tertiary-text-emphasis: 	shade-color($tertiary, 60%);
$success-text-emphasis:   shade-color($success, 40%);
$info-text-emphasis:      shade-color($info, 60%);
$warning-text-emphasis:   shade-color($warning, 50%);
$danger-text-emphasis:    shade-color($danger, 25%);
$light-text-emphasis:     $gray-700;
$dark-text-emphasis:      $gray-700;
// scss-docs-end theme-text-variables

// scss-docs-start theme-bg-subtle-variables
$primary-bg-subtle:       tint-color($primary, 85%);
$secondary-bg-subtle:     tint-color($secondary, 45%);
$tertiary-bg-subtle:  		tint-color($tertiary, 40%); //custom_bootstrap
$success-bg-subtle:       tint-color($success, 75%);
$info-bg-subtle:          tint-color($info, 70%);
$warning-bg-subtle:       tint-color($warning, 80%);
$danger-bg-subtle:        tint-color($danger, 90%);
$light-bg-subtle:         tint-color($light, 40%);
$dark-bg-subtle:          tint-color($dark, 60%);

// scss-docs-end theme-bg-subtle-variables

// scss-docs-start theme-border-subtle-variables
$primary-border-subtle:   tint-color($primary, 60%);
$secondary-border-subtle: tint-color($secondary, 60%);
$tertiary-border-subtle: 	tint-color($tertiary, 60%);
$success-border-subtle:   tint-color($success, 30%);
$info-border-subtle:      tint-color($info, 60%);
$warning-border-subtle:   tint-color($warning, 60%);
$danger-border-subtle:    tint-color($danger, 30%);
$light-border-subtle:     tint-color($gray-400, 60%);
$dark-border-subtle:      tint-color($gray-700, 60%);

// scss-docs-end theme-border-subtle-variables

// Dark color mode variables
//
// Custom variables for the `[data-bs-theme="dark"]` theme. Use this as a starting point for your own custom color modes by creating a new theme-specific file like `_variables-dark.scss` and adding the variables you need.
$theme-colors-dark: (
  "primary": 			$primary-dark,
  "secondary": 		$secondary-dark,
	"tertiary": 		$tertiary-dark,
  "success": 			$success-dark,
  "danger": 			$danger-dark,
	"correct":			$correct-dark,
	"incorrect":		$incorrect-dark,
	"unsure":				$unsure-dark,
  "info": 				$info-dark,
  "warning": 			$warning-dark,
	"light":      	$light-dark,
  "dark":       	$dark-dark,
);

// dark mode text color

$primary-text-emphasis-dark:        tint-color($primary, 40%);
$secondary-text-emphasis-dark:      tint-color($secondary, 40%);
$success-text-emphasis-dark:        shade-color($success, 40%);
$info-text-emphasis-dark:           tint-color($info, 40%);
$warning-text-emphasis-dark:        shade-color($warning, 40%);
$danger-text-emphasis-dark:         shade-color($danger, 40%);
$light-text-emphasis-dark:          $gray-100;
$dark-text-emphasis-dark:           $gray-300;
// scss-docs-end theme-text-dark-variables

// dark mode bg colors
$primary-bg-subtle-dark:            shade-color($primary, 40%);
$secondary-bg-subtle-dark:          shade-color($secondary, 80%);
$success-bg-subtle-dark:            shade-color($success, 80%);
$info-bg-subtle-dark:               shade-color($info, 80%);
$warning-bg-subtle-dark:            shade-color($warning, 80%);
$danger-bg-subtle-dark:             shade-color($danger, 80%);
$light-bg-subtle-dark:              $gray-200;//card bg dark mode
$dark-bg-subtle-dark:               $gray-400;
// scss-docs-end theme-bg-subtle-dark-variables

// scss-docs-start theme-border-subtle-dark-variables
$primary-border-subtle-dark:        shade-color($primary, 40%);
$secondary-border-subtle-dark:      shade-color($secondary, 40%);
$success-border-subtle-dark:        shade-color($success, 40%);
$info-border-subtle-dark:           shade-color($info, 40%);
$warning-border-subtle-dark:        shade-color($warning, 40%);
$danger-border-subtle-dark:         shade-color($danger, 40%);
$light-border-subtle-dark:          $gray-300;
$dark-border-subtle-dark:           $gray-800;
// scss-docs-end theme-border-subtle-dark-variables


// Body
//
// Settings for the `<body>` element.

$body-color:               $gray-900;//$dark-text-emphasis;// #000;//#121212;//$gray-900;
$body-bg:                  #EFEFF0;//$light;/// #eeeeee;///#f4f4f5;//#EFEFF0;

$body-secondary-color:      rgba($body-color, .75);
$body-secondary-bg:         $gray-300;

$body-tertiary-color:       rgba($body-color, .5);
$body-tertiary-bg:          $gray-100;

$body-emphasis-color:       $black;

$body-color-dark:                   $gray-300;
$body-bg-dark:                      #00040e;//$dark-dark;//#202531;
$body-secondary-color-dark:         rgba($body-color-dark, .75) !default;
$body-secondary-bg-dark:            #000c28;//$dark; //left main nav
$body-tertiary-color-dark:          rgba($body-color-dark, .5) !default;
$body-tertiary-bg-dark:             mix($gray-800, $gray-900, 50%) !default;
$body-emphasis-color-dark:          $white !default;
$border-color-dark:                 $gray-700 !default;
$border-color-translucent-dark:     rgba($white, .15) !default;
$headings-color-dark:               inherit !default;
$link-color-dark:                   tint-color($primary, 58%);
$link-hover-color-dark:             shift-color($link-color-dark, 30%);
//$code-color-dark:                   tint-color($code-color, 40%) !default;
//$mark-color-dark:                   $body-color-dark !default;
//$mark-bg-dark:                      $yellow-800 !default;

//
// Forms
//

$form-select-indicator-color-dark:  $body-color-dark;
$form-select-indicator-dark:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color-dark}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>");

$form-switch-color-dark:            rgba($dark, .5);
$form-switch-bg-image-dark:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color-dark}'/></svg>");

// scss-docs-start form-validation-colors-dark
$form-valid-color-dark:             $success-text-emphasis-dark;
$form-valid-border-color-dark:      $success-border-subtle-dark;
$form-invalid-color-dark:           $danger-text-emphasis-dark;
$form-invalid-border-color-dark:    $danger-border-subtle-dark;
// scss-docs-end form-validation-colors-dark


//
// Accordion
//

$accordion-icon-color-dark:         $primary-text-emphasis-dark;
$accordion-icon-active-color-dark:  $primary-text-emphasis-dark;

$accordion-button-icon-dark:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color-dark}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$accordion-button-active-icon-dark:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color-dark}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
// scss-docs-end sass-dark-mode-vars


// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<", "%3c"),
  (">", "%3e"),
  ("#", "%23"),
  ("(", "%28"),
  (")", "%29"),
);

// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:                true;
$enable-rounded:              true;
$enable-shadows:              true;
$enable-gradients:            false;
$enable-transitions:          true;
$enable-reduced-motion:       true;
$enable-smooth-scroll:        true;
$enable-grid-classes:         true;
$enable-container-classes:    true;
$enable-cssgrid:              false;
$enable-button-pointers:      true;
$enable-rfs:                  true;
$enable-validation-icons:     true;
$enable-negative-margins:     true;
$enable-deprecation-messages: true;
$enable-important-utilities:  true;

$enable-dark-mode:            true;
$color-mode-type:             data; // `data` or `media-query`

// Prefix for :root CSS variables

$variable-prefix:             bs-; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix:                      $variable-prefix;


// scss-docs-start spacer-variables-maps
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);
// scss-docs-end spacer-variables-maps



// Links
//
// Style anchor elements.

$link-color:                              $primary-text-emphasis;
$link-decoration:                         underline;
$link-shade-percentage:                   30%;
$link-hover-color:                        shift-color($link-color, $link-shade-percentage);
$link-hover-decoration:                   null;

$stretched-link-pseudo-element:           after;
$stretched-link-z-index:                  1;

// Icon links
// scss-docs-start icon-link-variables
$icon-link-gap:               .275rem;
$icon-link-underline-offset:  .25em;
$icon-link-icon-size:         .85em;
$icon-link-icon-transition:   .2s ease-in-out transform;
$icon-link-icon-transform:    translate3d(0, .2em, 0);
// scss-docs-end icon-link-variables

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 720px,
  lg: 1024px,
  xl: 1440px,
  xxl: 1920px
);
// scss-docs-end grid-breakpoints

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 576px,
  md: 720px,
  lg: 1024px,
  xl:1440px,//xl: 1140px,
  xxl:1920px//xxl: 1320px
);
// scss-docs-end container-max-widths

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           1.5rem;
$grid-row-columns:            6;

// Container padding

$container-padding-x: $grid-gutter-width;


// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
$border-width:                1px;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
);
$border-style:                solid;
$border-color:                $gray-400;//$body-bg;
$border-color-translucent:    rgba($black, .175);//rgba($black, .175);
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius:               1rem;
$border-radius-sm:            .375rem;//.25rem;
$border-radius-lg:            1.5rem;
$border-radius-xl:            2rem;
$border-radius-xxl:           3rem;
$border-radius-pill:          50rem;
// scss-docs-end border-radius-variables
// fusv-disable
$border-radius-2xl:           $border-radius-xxl; // Deprecated in v5.3.0
// fusv-enable

// scss-docs-start box-shadow-variables
$box-shadow:                  0 .5rem 1rem rgba($black, .15);
$box-shadow-sm:               0 .125rem .25rem rgba($black, .075);
$box-shadow-lg:               0 1rem 3rem rgba($black, .175);
$box-shadow-inset:            inset 0 1px 2px rgba($black, .075);
// scss-docs-end box-shadow-variables

$component-active-color:      $white;//$primary;//inherit;//$white;
$component-active-bg:         $primary;//$primary-bg-subtle;//rgba($primary, 1);

// scss-docs-start focus-ring-variables
$focus-ring-width:      1px;//.15rem;
$focus-ring-opacity:    .45;
$focus-ring-color:      rgba($info, $focus-ring-opacity);
$focus-ring-blur:       5px ;
$focus-ring-box-shadow: 0 0 $focus-ring-blur $focus-ring-width $focus-ring-color;
// scss-docs-end focus-ring-variables

// scss-docs-start caret-variables
$caret-width:                 .3em;
$caret-vertical-align:        $caret-width * .85;
$caret-spacing:               $caret-width * .85;
// scss-docs-end caret-variables

$transition-base:             all .2s ease-in-out;
$transition-fade:             opacity .25s linear;
// scss-docs-start collapse-transition
$transition-collapse:         height .35s ease;
$transition-collapse-width:   width .35s ease;
// scss-docs-end collapse-transition

// stylelint-disable function-disallowed-list
// scss-docs-start aspect-ratios
$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%)
);
// scss-docs-end aspect-ratios
// stylelint-enable function-disallowed-list

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif:      system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
// stylelint-enable value-keyword-case
$font-family-base:            "Open Sans", "Segoe UI", Tahoma, sans-serif;


// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root:              16px;
$font-size-base:              1.0625rem; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875;
$font-size-lg:                $font-size-base * 1.25;

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-medium:          500;
$font-weight-semibold:        600;
$font-weight-bold:            700;
$font-weight-bolder:          bolder;

$font-weight-base:            $font-weight-normal;

$line-height-base:            1.5;
$line-height-sm:              1.3;
$line-height-lg:              1.6;

$h1-font-size:                $font-size-base * 2.6;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.7;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base * 1.125;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size
);
// scss-docs-end font-sizes

// scss-docs-start headings-variables
$headings-margin-bottom:      $spacer * .5;
$headings-font-family:        $font-family-base;
$headings-font-style:         null;
$headings-font-weight:        500;
$headings-line-height:        1.3;
$headings-color:              inherit;//$body-color;
// scss-docs-end headings-variables

// scss-docs-start display-headings
$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem
);

$display-font-family: $font-family-base;
$display-font-style:  null;
$display-font-weight: 300;
$display-line-height: $headings-line-height;
// scss-docs-end display-headings

// scss-docs-start type-variables
$lead-font-size:              $font-size-base * 1.25;
$lead-font-weight:            400;

$small-font-size:             .875em;

$sub-sup-font-size:           .75em;


$initialism-font-size:        $small-font-size;

$blockquote-margin-y:         $spacer;
$blockquote-font-size:        $font-size-base * 1.25;
$blockquote-footer-color:     $gray-600;
$blockquote-footer-font-size: $small-font-size;

$hr-margin-y:                 $spacer;
$hr-color:                    inherit;

// fusv-disable
$hr-bg-color:                 null; // Deprecated in v5.2.0
$hr-height:                   null; // Deprecated in v5.2.0
// fusv-enable

$hr-border-color:             null; // Allows for inherited colors
$hr-border-width:             1px;
$hr-opacity:                  .25;

$legend-margin-bottom:        .5rem;
$legend-font-size:            1.5rem;
$legend-font-weight:          null;

$dt-font-weight:              $font-weight-bold;

$list-inline-padding:         .5rem;

$mark-padding:                .1875em;
$mark-bg:                     $highlighter-yellow;
// scss-docs-end type-variables


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y:        .75rem;
$table-cell-padding-x:        1rem;
$table-cell-padding-y-sm:     .25rem;
$table-cell-padding-x-sm:     .25rem;

$table-cell-vertical-align:   top;

$table-color:                 var(--#{$prefix}dark-text-emphasis);
$table-bg:                    transparent;//var(--#{$prefix}light-bg-subtle);
$table-accent-bg:             transparent;//var(--#{$prefix}light-bg-subtle);

$table-th-font-weight:        null;

$table-striped-color:         $table-color;
$table-striped-bg-factor:     .05;
$table-striped-bg:            rgba($black, $table-striped-bg-factor);

$table-active-color:          $table-color;
$table-active-bg-factor:      .1;
$table-active-bg:             rgba($black, $table-active-bg-factor);

$table-hover-color:           $table-color;
$table-hover-bg-factor:       .075;
$table-hover-bg:              rgba($black, $table-hover-bg-factor);

$table-border-factor:         .1;
$table-border-width:          var(--#{$prefix}border-width);
$table-border-color:          var(--#{$prefix}gray-500);

$table-striped-order:         odd;
$table-striped-columns-order: even;

$table-group-separator-color: currentcolor;

$table-caption-color:         var(--#{$prefix}secondary-color);

$table-bg-scale:              -80%;
// scss-docs-end table-variables

// scss-docs-start table-loop
$table-variants: (
  "primary":    shift-color($primary, $table-bg-scale),
  "secondary":  shift-color($secondary, $table-bg-scale),
  "success":    shift-color($success, $table-bg-scale),
  "info":       shift-color($info, $table-bg-scale),
  "warning":    shift-color($warning, $table-bg-scale),
  "danger":     shift-color($danger, $table-bg-scale),
  "light":      $light,
  "dark":       $dark,
);
// scss-docs-end table-loop


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y:         .375rem;
$input-btn-padding-x:         .65rem;
$input-btn-font-family:       null;
$input-btn-font-size:         $font-size-base;
$input-btn-line-height:       $line-height-base;

$input-btn-focus-width:         $focus-ring-width;
$input-btn-focus-color-opacity: $focus-ring-opacity;
$input-btn-focus-color:         $focus-ring-color;
$input-btn-focus-blur:          $focus-ring-blur;
$input-btn-focus-box-shadow:    $focus-ring-box-shadow;

$input-btn-padding-y-sm:      .25rem;
$input-btn-padding-x-sm:      .45rem;
$input-btn-font-size-sm:      $font-size-sm;

$input-btn-padding-y-lg:      .5rem;
$input-btn-padding-x-lg:      1rem;
$input-btn-font-size-lg:      $font-size-lg;

$input-btn-border-width:      var(--#{$prefix}border-width);
// scss-docs-end input-btn-variables


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-color:                   var(--#{$prefix}body-color);
$btn-padding-y:               $input-btn-padding-y;
$btn-padding-x:               $input-btn-padding-x;
$btn-font-family:             $input-btn-font-family;
$btn-font-size:               $input-btn-font-size;
$btn-line-height:             $input-btn-line-height;
$btn-white-space:             nowrap; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:            $input-btn-padding-y-sm;
$btn-padding-x-sm:            $input-btn-padding-x-sm;
$btn-font-size-sm:            $input-btn-font-size-sm;

$btn-padding-y-lg:            $input-btn-padding-y-lg;
$btn-padding-x-lg:            $input-btn-padding-x-lg;
$btn-font-size-lg:            $input-btn-font-size-lg;

$btn-border-width:            $input-btn-border-width;

$btn-font-weight:             $font-weight-normal;
$btn-box-shadow:              none;//inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075);
$btn-focus-width:             $focus-ring-width;//$input-btn-focus-width;
$btn-focus-box-shadow:        $focus-ring-box-shadow;//$input-btn-focus-box-shadow;
$btn-disabled-opacity:        .65;
$btn-active-box-shadow:       inset 1px 3px 5px rgba($black, .125);

$btn-link-color:              var(--#{$prefix}link-color);
$btn-link-hover-color:        var(--#{$prefix}link-hover-color);
$btn-link-disabled-color:     $gray-600;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           var(--#{$prefix}border-radius);
$btn-border-radius-sm:        var(--#{$prefix}border-radius-sm);
$btn-border-radius-lg:        var(--#{$prefix}border-radius-lg);

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$btn-hover-bg-shade-amount:       35%;
$btn-hover-bg-tint-amount:        35%;
$btn-hover-border-shade-amount:   30%;
$btn-hover-border-tint-amount:    20%;
$btn-active-bg-shade-amount:      30%;
$btn-active-bg-tint-amount:       30%;
$btn-active-border-shade-amount:  35%;
$btn-active-border-tint-amount:   20%;
// scss-docs-end btn-variables


// Forms

// scss-docs-start form-text-variables
$form-text-margin-top:                  .25rem;
$form-text-font-size:                   $small-font-size;
$form-text-font-style:                  null;
$form-text-font-weight:                 null;
$form-text-color:                       var(--#{$prefix}secondary-color);
// scss-docs-end form-text-variables

// scss-docs-start form-label-variables
$form-label-margin-bottom:              .5rem;
$form-label-font-size:                  null;
$form-label-font-style:                 null;
$form-label-font-weight:                null;
$form-label-color:                      null;
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
$input-padding-y:                       $input-btn-padding-y;
$input-padding-x:                       $input-btn-padding-x;
$input-font-family:                     $input-btn-font-family;
$input-font-size:                       $input-btn-font-size;
$input-font-weight:                     $font-weight-base;
$input-line-height:                     $input-btn-line-height;

$input-padding-y-sm:                    $input-btn-padding-y-sm;
$input-padding-x-sm:                    $input-btn-padding-x-sm;
$input-font-size-sm:                    $input-btn-font-size-sm;

$input-padding-y-lg:                    $input-btn-padding-y-lg;
$input-padding-x-lg:                    $input-btn-padding-x-lg;
$input-font-size-lg:                    $input-btn-font-size-lg;

$input-bg:                              var(--bs-body-bg);//rgba($white, .65);//var(--#{$prefix}light-bg);
$input-disabled-color:                  null;
$input-disabled-bg:                     var(--#{$prefix}secondary-bg);
$input-disabled-border-color:           null;

$input-color:                           var(--#{$prefix}body-color);
$input-border-color:                    var(--#{$prefix}gray-500);//var(--#{$prefix}border-color);
$input-border-width:                    $input-btn-border-width;
$input-box-shadow:                      $box-shadow-inset;

$input-border-radius:                   var(--#{$prefix}border-radius-sm);
$input-border-radius-sm:                var(--#{$prefix}border-radius-sm);
$input-border-radius-lg:                var(--#{$prefix}border-radius-lg);

$input-focus-bg:                        $input-bg;
$input-focus-border-color:              tint-color($component-active-bg, 50%);
$input-focus-color:                     $input-color;
$input-focus-width:                     $input-btn-focus-width;
$input-focus-box-shadow:                $input-btn-focus-box-shadow;

$input-placeholder-color:               var(--#{$prefix}gray-500);
$input-plaintext-color:                 var(--#{$prefix}body-color);

$input-height-border:                   calc(#{$input-border-width} * 2); // stylelint-disable-line function-disallowed-list

$input-height-inner:                    add($input-line-height * 1em, $input-padding-y * 2);
$input-height-inner-half:               add($input-line-height * .5em, $input-padding-y);
$input-height-inner-quarter:            add($input-line-height * .25em, $input-padding-y * .5);

$input-height:                          add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false));
$input-height-sm:                       add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false));
$input-height-lg:                       add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false));

$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$form-color-width:                      3rem;
// scss-docs-end form-input-variables

// scss-docs-start form-check-variables
$form-check-input-width:                  1em;
$form-check-min-height:                   $font-size-base * $line-height-base;
$form-check-padding-start:                $form-check-input-width + .5em;
$form-check-margin-bottom:                .25rem;
$form-check-label-color:                  null;
$form-check-label-cursor:                 null;
$form-check-transition:                   null;

$form-check-input-active-filter:          brightness(90%);

$form-check-input-bg:                     var(--bs-body-bg);//$input-bg;
$form-check-input-border:                 var(--#{$prefix}border-width) solid var(--#{$prefix}border-color);
$form-check-input-border-radius:          .25em;
$form-check-radio-border-radius:          50%;
$form-check-input-focus-border:           $input-focus-border-color;
$form-check-input-focus-box-shadow:       $focus-ring-box-shadow;

$form-check-input-checked-color:          $primary-text-emphasis;//$component-active-color;//var(--#{$prefix}body-color);//$component-active-color;
$form-check-input-checked-bg-color:       $component-active-bg;
$form-check-input-checked-border-color:   $form-check-input-checked-bg-color;
$form-check-input-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>");
$form-check-radio-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>");

$form-check-input-indeterminate-color:          $component-active-color;
$form-check-input-indeterminate-bg-color:       $component-active-bg;
$form-check-input-indeterminate-border-color:   $form-check-input-indeterminate-bg-color;
$form-check-input-indeterminate-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");

$form-check-input-disabled-opacity:        .5;
$form-check-label-disabled-opacity:        $form-check-input-disabled-opacity;
$form-check-btn-check-disabled-opacity:    $btn-disabled-opacity;

$form-check-inline-margin-end:    1rem;
// scss-docs-end form-check-variables

// scss-docs-start form-switch-variables
$form-switch-color:               $primary;//rgba($secondary, .5);
$form-switch-width:               2em;
$form-switch-padding-start:       $form-switch-width + .5em;
$form-switch-bg-image:            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>");
$form-switch-border-radius:       $form-switch-width;
$form-switch-transition:          background-position .15s ease-in-out;

$form-switch-focus-color:         $component-active-color;//$input-focus-border-color;
$form-switch-focus-bg-image:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>");

$form-switch-checked-color:       $component-active-color;
$form-switch-checked-bg-image:    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>");
$form-switch-checked-bg-position: right center;
// scss-docs-end form-switch-variables

// scss-docs-start input-group-variables
$input-group-addon-padding-y:           $input-padding-y;
$input-group-addon-padding-x:           $input-padding-x;
$input-group-addon-font-weight:         $input-font-weight;
$input-group-addon-color:               $input-color;
$input-group-addon-bg:                  var(--#{$prefix}tertiary-bg);
$input-group-addon-border-color:        $input-border-color;
// scss-docs-end input-group-variables

// scss-docs-start form-select-variables
$form-select-padding-y:             $input-padding-y;
$form-select-padding-x:             $input-padding-x;
$form-select-font-family:           $input-font-family;
$form-select-font-size:             $input-font-size;
$form-select-indicator-padding:     $form-select-padding-x * 3; // Extra padding for background-image
$form-select-font-weight:           $input-font-weight;
$form-select-line-height:           $input-line-height;
$form-select-color:                 $input-color;
$form-select-bg:                    $input-bg;
$form-select-disabled-color:        null;
$form-select-disabled-bg:           $input-disabled-bg;
$form-select-disabled-border-color: $input-disabled-border-color;
$form-select-bg-position:           right $form-select-padding-x center;
$form-select-bg-size:               16px 12px; // In pixels because image dimensions
$form-select-indicator-color:       $gray-800;
$form-select-indicator:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>");

$form-select-feedback-icon-padding-end: $form-select-padding-x * 2.5 + $form-select-indicator-padding;
$form-select-feedback-icon-position:    center right $form-select-indicator-padding;
$form-select-feedback-icon-size:        $input-height-inner-half $input-height-inner-half;

$form-select-border-width:        $input-border-width;
$form-select-border-color:        $input-border-color;
$form-select-border-radius:       $input-border-radius;
$form-select-box-shadow:          $box-shadow-inset;

$form-select-focus-border-color:  $input-focus-border-color ;
$form-select-focus-width:         $input-focus-width;
$form-select-focus-box-shadow:    0 0 0 $form-select-focus-width $input-btn-focus-color ;

$form-select-padding-y-sm:        $input-padding-y-sm;
$form-select-padding-x-sm:        $input-padding-x-sm;
$form-select-font-size-sm:        $input-font-size-sm;
$form-select-border-radius-sm:    $input-border-radius-sm;

$form-select-padding-y-lg:        $input-padding-y-lg;
$form-select-padding-x-lg:        $input-padding-x-lg;
$form-select-font-size-lg:        $input-font-size-lg;
$form-select-border-radius-lg:    $input-border-radius-lg;

$form-select-transition:          $input-transition;
// scss-docs-end form-select-variables

// scss-docs-start form-range-variables
$form-range-track-width:          100%;
$form-range-track-height:         .5rem;
$form-range-track-cursor:         pointer;
$form-range-track-bg:             var(--#{$prefix}tertiary-bg);
$form-range-track-border-radius:  1rem;
$form-range-track-box-shadow:     $box-shadow-inset;

$form-range-thumb-width:                   1rem;
$form-range-thumb-height:                  $form-range-thumb-width;
$form-range-thumb-bg:                      $component-active-bg;
$form-range-thumb-border:                  0;
$form-range-thumb-border-radius:           1rem;
$form-range-thumb-box-shadow:              0 .1rem .25rem rgba($black, .1);
$form-range-thumb-focus-box-shadow:        0 0 0 1px $body-bg, $input-focus-box-shadow;
$form-range-thumb-focus-box-shadow-width:  $input-focus-width; // For focus box shadow issue in Edge
$form-range-thumb-active-bg:               tint-color($component-active-bg, 70%);
$form-range-thumb-disabled-bg:             var(--#{$prefix}secondary-color);
$form-range-thumb-transition:              background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
// scss-docs-end form-range-variables

// scss-docs-start form-file-variables
$form-file-button-color:          $input-color;
$form-file-button-bg:             var(--#{$prefix}tertiary-bg);
$form-file-button-hover-bg:       var(--#{$prefix}secondary-bg);
// scss-docs-end form-file-variables

// scss-docs-start form-floating-variables
$form-floating-height:                  add(3.5rem, $input-height-border);
$form-floating-line-height:             1.25;
$form-floating-padding-x:               $input-padding-x;
$form-floating-padding-y:               1rem;
$form-floating-input-padding-t:         1.625rem;
$form-floating-input-padding-b:         .625rem;
$form-floating-label-height:            1.5em;
$form-floating-label-opacity:           .65;
$form-floating-label-transform:         scale(.85) translateY(-.5rem) translateX(.15rem);
$form-floating-label-disabled-color:    $gray-600;
$form-floating-transition:              opacity .1s ease-in-out, transform .1s ease-in-out;
// scss-docs-end form-floating-variables

// Form validation

// scss-docs-start form-feedback-variables
$form-feedback-margin-top:          $form-text-margin-top;
$form-feedback-font-size:           $form-text-font-size;
$form-feedback-font-style:          $form-text-font-style;
$form-feedback-valid-color:         $success;
$form-feedback-invalid-color:       $danger;

$form-feedback-icon-valid-color:    $form-feedback-valid-color;
$form-feedback-icon-valid:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
$form-feedback-icon-invalid-color:  $form-feedback-invalid-color;
$form-feedback-icon-invalid:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");
// scss-docs-end form-feedback-variables

// scss-docs-start form-validation-colors
$form-valid-color:                  $form-feedback-valid-color;
$form-valid-border-color:           $form-feedback-valid-color;
$form-invalid-color:                $form-feedback-invalid-color;
$form-invalid-border-color:         $form-feedback-invalid-color;
// scss-docs-end form-validation-colors

// scss-docs-start form-validation-states
$form-validation-states: (
  "valid": (
    "color": var(--#{$prefix}form-valid-color),
    "icon": $form-feedback-icon-valid,
    "tooltip-color": #fff,
    "tooltip-bg-color": var(--#{$prefix}success),
    "focus-box-shadow": 0 0 $input-btn-focus-blur $input-focus-width rgba(var(--#{$prefix}success-rgb), $input-btn-focus-color-opacity),
    "border-color": var(--#{$prefix}form-valid-border-color),
  ),
  "invalid": (
    "color": var(--#{$prefix}form-invalid-color),
    "icon": $form-feedback-icon-invalid,
    "tooltip-color": #fff,
    "tooltip-bg-color": var(--#{$prefix}danger),
    "focus-box-shadow": 0 0 $input-btn-focus-blur $input-focus-width rgba(var(--#{$prefix}danger-rgb), $input-btn-focus-color-opacity),
    "border-color": var(--#{$prefix}form-invalid-border-color),
  )
);
// scss-docs-end form-validation-states

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
$zindex-dropdown:                   1000;
$zindex-sticky:                     1020;
$zindex-fixed:                      1030;
$zindex-offcanvas-backdrop:         1040;
$zindex-offcanvas:                  1045;
$zindex-modal-backdrop:             1050;
$zindex-modal:                      1055;
$zindex-popover:                    1070;
$zindex-tooltip:                    1080;
$zindex-toast:                      1090;
// scss-docs-end zindex-stack

// scss-docs-start zindex-levels-map
$zindex-levels: (
  n1: -1,
  0: 0,
  1: 1,
  2: 2,
  3: 3
);
// scss-docs-end zindex-levels-map


// Navs

// scss-docs-start nav-variables
$nav-link-padding-y:                1rem;
$nav-link-padding-x:                1rem;
$nav-link-font-size:                $font-size-base;//null;
$nav-link-font-weight:              $font-weight-normal;//null;
$nav-link-color:                    var(--#{$prefix}link-color);
$nav-link-hover-color:              var(--#{$prefix}link-hover-color);
$nav-link-transition:               color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
$nav-link-disabled-color:           var(--#{$prefix}secondary-color);
$nav-link-focus-box-shadow:         $focus-ring-box-shadow;

$nav-tabs-border-color:             var(--#{$prefix}border-color);
$nav-tabs-border-width:             var(--#{$prefix}border-width);
$nav-tabs-border-radius:            var(--#{$prefix}border-radius);
$nav-tabs-link-hover-border-color:  var(--#{$prefix}secondary-bg) var(--#{$prefix}secondary-bg) $nav-tabs-border-color;
$nav-tabs-link-active-color:        var(--#{$prefix}emphasis-color);
$nav-tabs-link-active-bg:           var(--#{$prefix}body-bg);
$nav-tabs-link-active-border-color: var(--#{$prefix}border-color) var(--#{$prefix}border-color) $nav-tabs-link-active-bg;

$nav-pills-border-radius:           var(--#{$prefix}border-radius-pill);
$nav-pills-link-active-color:       $component-active-color;
$nav-pills-link-active-bg:          $component-active-bg;

$nav-underline-gap:                 1rem;
$nav-underline-border-width:        .35rem;//.125rem;
$nav-underline-link-active-color:   var(--#{$prefix}link-color);
// scss-docs-end nav-variables


// Navbar

// scss-docs-start navbar-variables
$navbar-padding-y:                  0;
$navbar-padding-x:                  null;

$navbar-nav-link-padding-x:         1rem;

$navbar-brand-font-size:            $font-size-lg;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2;
$navbar-brand-height:               $navbar-brand-font-size * $line-height-base;
$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) * .5;
$navbar-brand-margin-end:           0;

$navbar-toggler-padding-y:          1rem;
$navbar-toggler-padding-x:          1rem;
$navbar-toggler-font-size:          $font-size-lg;
$navbar-toggler-border-radius:      0;
$navbar-toggler-focus-width:        $btn-focus-width;
$navbar-toggler-transition:         box-shadow .15s ease-in-out;

$navbar-light-color:                rgba($white, 1);
$navbar-light-hover-color:          rgba($white, .75);
$navbar-light-active-color:         rgba($white, 1);
$navbar-light-disabled-color:       rgba($white, .25);
$navbar-light-icon-color:           rgba($white, .9);
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-toggler-border-color: none;
$navbar-light-brand-color:          $navbar-light-active-color;
$navbar-light-brand-hover-color:    $navbar-light-active-color;
// scss-docs-end navbar-variables

// scss-docs-start navbar-dark-variables
$navbar-dark-color:                 rgba($white, .75);
$navbar-dark-hover-color:           rgba($white, 1);
$navbar-dark-active-color:          $white;
$navbar-dark-disabled-color:        rgba($white, .25);
$navbar-dark-icon-color:            rgba($white, 1);
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-dark-toggler-border-color:  none;
$navbar-dark-brand-color:           $navbar-dark-active-color;
$navbar-dark-brand-hover-color:     $navbar-dark-active-color;
// scss-docs-end navbar-dark-variables


// Dropdowns
//
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
$dropdown-min-width:                10rem;
$dropdown-padding-x:                0;
$dropdown-padding-y:                0;
$dropdown-spacer:                   .125rem;
$dropdown-font-size:                $font-size-base;
$dropdown-color:                    var(--#{$prefix}body-color);
$dropdown-bg:                       var(--#{$prefix}body-bg) !default;//var(--#{$prefix}white);
$dropdown-border-color:             var(--#{$prefix}border-color-translucent);
$dropdown-border-radius:            var(--#{$prefix}border-radius);
$dropdown-border-width:             var(--#{$prefix}border-width);
$dropdown-inner-border-radius:      calc(#{$dropdown-border-radius} - #{$dropdown-border-width}); // stylelint-disable-line function-disallowed-list
$dropdown-divider-bg:               $dropdown-border-color;
$dropdown-divider-margin-y:         $spacer * .5;
$dropdown-box-shadow:               $box-shadow;

$dropdown-link-color:               var(--#{$prefix}body-color);
$dropdown-link-hover-color:         $dropdown-link-color;
$dropdown-link-hover-bg:            var(--#{$prefix}primary-bg-subtle);

$dropdown-link-active-color:        $component-active-color;
$dropdown-link-active-bg:           $component-active-bg;

$dropdown-link-disabled-color:      var(--#{$prefix}tertiary-color);

$dropdown-item-padding-y:           $spacer * .5;
$dropdown-item-padding-x:           $spacer;

$dropdown-header-color:             $gray-600;
$dropdown-header-padding-x:         $dropdown-item-padding-x;
$dropdown-header-padding-y:         $dropdown-padding-y;
// fusv-disable
$dropdown-header-padding:           $dropdown-header-padding-y $dropdown-header-padding-x; // Deprecated in v5.2.0
// fusv-enable
// scss-docs-end dropdown-variables

// scss-docs-start dropdown-dark-variables
$dropdown-dark-color:               $gray-300;
$dropdown-dark-bg:                  $gray-800;
$dropdown-dark-border-color:        $dropdown-border-color;
$dropdown-dark-divider-bg:          $dropdown-divider-bg;
$dropdown-dark-box-shadow:          null;
$dropdown-dark-link-color:          $dropdown-dark-color;
$dropdown-dark-link-hover-color:    $white;
$dropdown-dark-link-hover-bg:       rgba($white, .15);
$dropdown-dark-link-active-color:   $dropdown-link-active-color;
$dropdown-dark-link-active-bg:      rgba($white, .15);//$dropdown-link-active-bg;
$dropdown-dark-link-disabled-color: $gray-500;
$dropdown-dark-header-color:        $gray-500;
// scss-docs-end dropdown-dark-variables


// Pagination

// scss-docs-start pagination-variables
$pagination-padding-y:              .5rem;
$pagination-padding-x:              1rem;
$pagination-padding-y-sm:           .25rem;
$pagination-padding-x-sm:           .5rem;
$pagination-padding-y-lg:           .75rem;
$pagination-padding-x-lg:           1.5rem;

$pagination-font-size:              $font-size-base;

$pagination-color:                  var(--#{$prefix}link-color);
$pagination-bg:                     var(--#{$prefix}secondary-bg);
$pagination-border-radius:          var(--#{$prefix}border-radius-sm);
$pagination-border-width:           var(--#{$prefix}border-width);
$pagination-margin-start:           calc(#{$pagination-border-width} * -1); // stylelint-disable-line function-disallowed-list
$pagination-border-color:           var(--#{$prefix}border-color);

$pagination-focus-color:            var(--#{$prefix}link-hover-color);
$pagination-focus-bg:               var(--#{$prefix}secondary-bg);
$pagination-focus-box-shadow:       $focus-ring-box-shadow;
$pagination-focus-outline:          0;

$pagination-hover-color:            var(--#{$prefix}link-hover-color);
$pagination-hover-bg:               rgba($primary, .5);//var(--#{$prefix}tertiary-bg);
$pagination-hover-border-color:     var(--#{$prefix}border-color); // Todo in v6: remove this?

$pagination-active-color:           $gray-600; // $component-active-color;
$pagination-active-bg:              rgba($white, .5);//$component-active-bg;
$pagination-active-border-color:    var(--#{$prefix}secondary-bg); //$component-active-bg;

$pagination-disabled-color:         $gray-600; //var(--#{$prefix}secondary-color);
$pagination-disabled-bg:            var(--#{$prefix}secondary-bg);
$pagination-disabled-border-color:  var(--#{$prefix}border-color);

$pagination-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$pagination-border-radius-sm:       var(--#{$prefix}border-radius-sm);
$pagination-border-radius-lg:       var(--#{$prefix}border-radius-lg);
// scss-docs-end pagination-variables


// Placeholders

// scss-docs-start placeholders
$placeholder-opacity-max:           .5;
$placeholder-opacity-min:           .2;
// scss-docs-end placeholders

// Cards

// scss-docs-start card-variables
$card-spacer-y:                     $spacer;
$card-spacer-x:                     $spacer;
$card-title-spacer-y:               $spacer * 1;
$card-title-color:                  null;
$card-subtitle-color:               null;
$card-border-width:                 var(--#{$prefix}border-width);
$card-border-color:                 var(--#{$prefix}border-color);//none;//var(--#{$prefix}border-color-translucent);
$card-border-radius:                var(--#{$prefix}border-radius-lg);
$card-box-shadow:                   null;
$card-inner-border-radius:          subtract($card-border-radius, $card-border-width);
$card-cap-padding-y:                $card-spacer-y * 1;
$card-cap-padding-x:                $card-spacer-x;
$card-cap-bg:                       var(--bs-list-group-bg);//$gray-400;//var(--#{$prefix}secondary-bg-subtle);
$card-cap-color:                    null;
$card-height:                       null;
$card-color:                        null;
$card-bg:                           var(--#{$prefix}white);//rgba($light-bg-subtle, 1);//var(--#{$prefix}light-bg-subtle);//var(--#{$prefix}light);
$card-img-overlay-padding:          $spacer;
$card-group-margin:                 $grid-gutter-width * .5;

// scss-docs-end card-variables

// Accordion

// scss-docs-start accordion-variables
$accordion-padding-y:                    1rem;
$accordion-padding-x:                     1.25rem;
$accordion-color:                         var(--#{$prefix}text-color);
$accordion-bg:                            var(--#{$prefix}card-bg);
$accordion-border-width:                  var(--#{$prefix}border-width);
$accordion-border-color:                  var(--#{$prefix}border-color);
$accordion-border-radius:                 var(--#{$prefix}border-radius);
$accordion-inner-border-radius:           subtract($accordion-border-radius, $accordion-border-width);

$accordion-body-padding-y:                $accordion-padding-y;
$accordion-body-padding-x:                $accordion-padding-x;

$accordion-button-padding-y:              $accordion-padding-y;
$accordion-button-padding-x:              $accordion-padding-x;
$accordion-button-color:                  var(--#{$prefix}body-color);
$accordion-button-bg:                     var(--#{$prefix}accordion-bg);
$accordion-transition:                    $btn-transition, border-radius .25s ease;
$accordion-button-active-bg:              rgba($tertiary, .1);//var(--#{$prefix}tertiary);//rgba($component-active-bg, .15);
$accordion-button-active-color:           var(--#{$prefix}dark-text-emphasis-color);

$accordion-button-focus-border-color:     $input-focus-border-color;
$accordion-button-focus-box-shadow:       $btn-focus-box-shadow;

$accordion-icon-width:                    1.25rem;
$accordion-icon-color:                    $primary;
$accordion-icon-active-color:             $primary-text-emphasis;
$accordion-icon-transition:               transform .2s ease-in-out;
$accordion-icon-transform:                rotate(-180deg);

$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
// scss-docs-end accordion-variables

// Tooltips

// scss-docs-start tooltip-variables
$tooltip-font-size:                 $font-size-sm;
$tooltip-max-width:                 220px;
$tooltip-color:                     var(--#{$prefix}body-bg);
$tooltip-bg:                        var(--#{$prefix}emphasis-color);
$tooltip-border-radius:             var(--#{$prefix}border-radius);
$tooltip-opacity:                   .9;
$tooltip-padding-y:                 $spacer * .25;
$tooltip-padding-x:                 $spacer * .5;
$tooltip-margin:                    null; // TODO: remove this in v6

$tooltip-arrow-width:               1rem;
$tooltip-arrow-height:              .5rem;
// fusv-disable
$tooltip-arrow-color:               null; // Deprecated in Bootstrap 5.2.0 for CSS variables
// fusv-enable
// scss-docs-end tooltip-variables

// Form tooltips must come after regular tooltips
// scss-docs-start tooltip-feedback-variables
$form-feedback-tooltip-padding-y:     $tooltip-padding-y;
$form-feedback-tooltip-padding-x:     $tooltip-padding-x;
$form-feedback-tooltip-font-size:     $tooltip-font-size;
$form-feedback-tooltip-line-height:   null;
$form-feedback-tooltip-opacity:       $tooltip-opacity;
$form-feedback-tooltip-border-radius: $tooltip-border-radius;
// scss-docs-end tooltip-feedback-variables


// Popovers

// scss-docs-start popover-variables
$popover-font-size:                 $font-size-sm;
$popover-bg:                        var(--#{$prefix}body-bg);
$popover-max-width:                 360px;
$popover-border-width:              var(--#{$prefix}border-width);
$popover-border-color:              var(--#{$prefix}border-color-translucent);
$popover-border-radius:             var(--#{$prefix}border-radius-lg);
$popover-inner-border-radius:       calc(#{$popover-border-radius} - #{$popover-border-width}); // stylelint-disable-line function-disallowed-list
$popover-box-shadow:                $box-shadow;

$popover-header-font-size:          $font-size-base;
$popover-header-bg:                 var(--#{$prefix}secondary-bg);
$popover-header-color:              $headings-color;
$popover-header-padding-y:          .5rem;
$popover-header-padding-x:          $spacer;

$popover-body-color:                var(--#{$prefix}body-color);
$popover-body-padding-y:            $spacer;
$popover-body-padding-x:            $spacer;

$popover-arrow-width:               1rem;
$popover-arrow-height:              .5rem;
// scss-docs-end popover-variables

// fusv-disable
// Deprecated in Bootstrap 5.2.0 for CSS variables
$popover-arrow-color:               $popover-bg;
$popover-arrow-outer-color:         var(--#{$prefix}border-color-translucent);
// fusv-enable


// Toasts

// scss-docs-start toast-variables
$toast-max-width:                   350px;
$toast-padding-x:                   .75rem;
$toast-padding-y:                   .5rem;
$toast-font-size:                   .875rem;
$toast-color:                       null;
$toast-background-color:            rgba(var(--#{$prefix}body-bg-rgb), .85);
$toast-border-width:                var(--#{$prefix}border-width);
$toast-border-color:                var(--#{$prefix}border-color-translucent);
$toast-border-radius:               var(--#{$prefix}border-radius);
$toast-box-shadow:                  var(--#{$prefix}box-shadow);
$toast-spacing:                     $container-padding-x;

$toast-header-color:                var(--#{$prefix}secondary-color);
$toast-header-background-color:     rgba(var(--#{$prefix}body-bg-rgb), .85);
$toast-header-border-color:         $toast-border-color;
// scss-docs-end toast-variables


// Badges

// scss-docs-start badge-variables
$badge-font-size:                   .8795em; //15px
$badge-font-weight:                 $font-weight-light;
$badge-color:                       var(--#{$prefix}body-color);
$badge-padding-y:                   $btn-padding-y-sm;
$badge-padding-x:                   $btn-padding-x-sm;
$badge-border-radius:               3px;///var(--#{$prefix}border-radius);
// scss-docs-end badge-variables


// Modals

// scss-docs-start modal-variables
$modal-inner-padding:               $spacer * 1;

$modal-footer-margin-between:       .5rem;

$modal-dialog-margin:               .5rem;
$modal-dialog-margin-y-sm-up:       1.75rem;

$modal-title-line-height:           $line-height-base;

$modal-content-color:               null;
$modal-content-bg:                  var(--#{$prefix}card-bg);
$modal-content-border-color:        var(--#{$prefix}border-color-translucent);
$modal-content-border-width:        var(--#{$prefix}border-width);
$modal-content-border-radius:       var(--#{$prefix}border-radius-lg);
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width);
$modal-content-box-shadow-xs:       $box-shadow-sm;
$modal-content-box-shadow-sm-up:    $box-shadow;

$modal-backdrop-bg:                 $black;
$modal-backdrop-opacity:            .65;

$modal-header-border-color:         var(--#{$prefix}border-color);
$modal-header-border-width:         $modal-content-border-width;
$modal-header-padding-y:            $modal-inner-padding;
$modal-header-padding-x:            $modal-inner-padding;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility

$modal-footer-bg:                   var(--#{$prefix}body-bg);
$modal-footer-border-color:         $modal-header-border-color;
$modal-footer-border-width:         $modal-header-border-width;

$modal-sm:                          300px;
$modal-md:                          600px;
$modal-lg:                          900px;
$modal-xl:                          1140px;

$modal-fade-transform:              translate(0, -50px);
$modal-show-transform:              none;
$modal-transition:                  transform .3s ease-out;
$modal-scale-transform:             scale(1.02);
// scss-docs-end modal-variables


// Alerts
//
// Define alert colors, border radius, and padding.

// scss-docs-start alert-variables
$alert-padding-y:               $spacer * .75;
$alert-padding-x:               $spacer * .75;
$alert-margin-bottom:           1rem;
$alert-border-radius:           var(--#{$prefix}border-radius);
$alert-link-font-weight:        $font-weight-bold;
$alert-border-width:            var(--#{$prefix}border-width);
$alert-dismissible-padding-r:   $alert-padding-x * 3; // 3x covers width of x plus default padding on either side
// scss-docs-end alert-variables

// fusv-disable
$alert-bg-scale:                -80%; // Deprecated in v5.2.0, to be removed in v6
$alert-border-scale:            -70%; // Deprecated in v5.2.0, to be removed in v6
$alert-color-scale:             40%; // Deprecated in v5.2.0, to be removed in v6
// fusv-enable

// Progress bars

// scss-docs-start progress-variables
$progress-height:                   1.25rem;
$progress-height-med:								.75rem;
$progress-height-sm:								.5625rem;
$progress-font-size:                $font-size-base * .65;
$progress-bg:                       rgba($gray-500, 1);
$progress-border-radius:            var(--#{$prefix}border-radius-pill);
$progress-box-shadow:               none;//inset 0px 1px 2px 0px rgba(125, 125, 125, 0.25), 0px 1px 0px 0px $gray-200;
$progress-bar-color:                $white;
$progress-bar-bg:                   $primary;
$progress-bar-animation-timing:     1s linear infinite;
$progress-bar-transition:           width .6s ease;
// scss-docs-end progress-variables


// List group

// scss-docs-start list-group-variables
$list-group-color:                  var(--#{$prefix}body-color);
$list-group-bg:                     var(--#{$prefix}white);//var(--#{$prefix}white);
$list-group-border-color:           var(--#{$prefix}border-color);
$list-group-border-width:           var(--#{$prefix}border-width);
$list-group-border-radius:          var(--#{$prefix}border-radius);

$list-group-item-padding-y:         $spacer * 1;
$list-group-item-padding-x:         $spacer * 1;
// fusv-disable
$list-group-item-bg-scale:          -80%; // Deprecated in v5.3.0
$list-group-item-color-scale:       40%; // Deprecated in v5.3.0
// fusv-enable

$list-group-hover-bg:               rgba($info, .15);//var(--#{$prefix}tertiary-bg);
$list-group-active-color:           $component-active-color;
$list-group-active-bg:              $component-active-bg;
$list-group-active-border-color:    $list-group-active-bg;

$list-group-disabled-color:         var(--#{$prefix}secondary-color);
$list-group-disabled-bg:            $input-disabled-bg;//rgba(var(--#{$prefix}emphasis-color-rgb), .1);

$list-group-action-color:           var(--#{$prefix}secondary-color);
$list-group-action-hover-color:     var(--#{$prefix}emphasis-color);

$list-group-action-active-color:    var(--#{$prefix}body-color);
$list-group-action-active-bg:       var(--#{$prefix}secondary-bg);
// scss-docs-end list-group-variables


// Image thumbnails

// scss-docs-start thumbnail-variables
$thumbnail-padding:                 .25rem;
$thumbnail-bg:                      transparent;
$thumbnail-border-width:            none;
$thumbnail-border-color:            none;
$thumbnail-border-radius:           none;
$thumbnail-box-shadow:              none;
// scss-docs-end thumbnail-variables


// Figures

// scss-docs-start figure-variables
$figure-caption-font-size:          $small-font-size;
$figure-caption-color:              var(--#{$prefix}secondary-color);
// scss-docs-end figure-variables


// Breadcrumbs

// scss-docs-start breadcrumb-variables
$breadcrumb-font-size:              $font-size-base;//null;
$breadcrumb-padding-y:              0;
$breadcrumb-padding-x:              0;
$breadcrumb-item-padding-x:         .5rem;
$breadcrumb-margin-bottom:          1rem;
$breadcrumb-bg:                     null;
$breadcrumb-divider-color:          $muted;//var(--#{$prefix}body-color);
$breadcrumb-active-color:           $muted;//var(--#{$prefix}dark);
$breadcrumb-divider:                quote("/");
$breadcrumb-divider-flipped:        $breadcrumb-divider;
$breadcrumb-border-radius:          null;
// scss-docs-end breadcrumb-variables

// Carousel

// scss-docs-start carousel-variables
$carousel-control-color:             $white;
$carousel-control-width:             15%;
$carousel-control-opacity:           .5;
$carousel-control-hover-opacity:     .9;
$carousel-control-transition:        opacity .15s ease;

$carousel-indicator-width:           30px;
$carousel-indicator-height:          3px;
$carousel-indicator-hit-area-height: 10px;
$carousel-indicator-spacer:          3px;
$carousel-indicator-opacity:         .5;
$carousel-indicator-active-bg:       $white;
$carousel-indicator-active-opacity:  1;
$carousel-indicator-transition:      opacity .6s ease;

$carousel-caption-width:             70%;
$carousel-caption-color:             $white;
$carousel-caption-padding-y:         1.25rem;
$carousel-caption-spacer:            1.25rem;

$carousel-control-icon-width:        2rem;

$carousel-control-prev-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
$carousel-control-next-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");

$carousel-transition-duration:       .6s;
$carousel-transition:                transform $carousel-transition-duration ease-in-out; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)
// scss-docs-end carousel-variables

// scss-docs-start carousel-dark-variables
$carousel-dark-indicator-active-bg:  $black;
$carousel-dark-caption-color:        $black;
$carousel-dark-control-icon-filter:  invert(1) grayscale(100);
// scss-docs-end carousel-dark-variables


// Spinners

// scss-docs-start spinner-variables
$spinner-width:           2rem;
$spinner-height:          $spinner-width;
$spinner-vertical-align:  -.125em;
$spinner-border-width:    .25em;
$spinner-animation-speed: .75s;

$spinner-width-sm:        1rem;
$spinner-height-sm:       $spinner-width-sm;
$spinner-border-width-sm: .2em;
// scss-docs-end spinner-variables


// Close

// scss-docs-start close-variables
$btn-close-width:            1em;
$btn-close-height:           $btn-close-width;
$btn-close-padding-x:        .25em;
$btn-close-padding-y:        $btn-close-padding-x;
$btn-close-color:            $primary;
$btn-close-bg:               url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>");
$btn-close-focus-shadow:     $focus-ring-box-shadow;
$btn-close-opacity:          .5;
$btn-close-hover-opacity:    .75;
$btn-close-focus-opacity:    1;
$btn-close-disabled-opacity: .25;
$btn-close-white-filter:     invert(1) grayscale(100%) brightness(200%);
// scss-docs-end close-variables


// Offcanvas

// scss-docs-start offcanvas-variables
$offcanvas-padding-y:               0;//$modal-inner-padding;
$offcanvas-padding-x:               0;//$modal-inner-padding;
$offcanvas-horizontal-width:        16rem;
$offcanvas-vertical-height:         30vh;
$offcanvas-transition-duration:     .3s;
$offcanvas-border-color:            $modal-content-border-color;
$offcanvas-border-width:            $modal-content-border-width;
$offcanvas-title-line-height:       $modal-title-line-height;
$offcanvas-bg-color:                var(--#{$prefix}secondary);
$offcanvas-color:                   null;//var(--#{$prefix}body-bg);
$offcanvas-box-shadow:              $modal-content-box-shadow-xs;
$offcanvas-backdrop-bg:             $modal-backdrop-bg;
$offcanvas-backdrop-opacity:        $modal-backdrop-opacity;
// scss-docs-end offcanvas-variables

// Code

$code-font-size:                    $small-font-size;
$code-color:                        $pink;

$kbd-padding-y:                     .1875rem;
$kbd-padding-x:                     .375rem;
$kbd-font-size:                     $code-font-size;
$kbd-color:                         var(--#{$prefix}body-bg);
$kbd-bg:                            var(--#{$prefix}body-color);
$nested-kbd-font-weight:            null; // Deprecated in v5.2.0, removing in v6

$pre-color:                         null;




